import { patchItem, getItemById } from "@/../../shared/api/SharedClient"

import * as providerClientService from '@/api/ProviderClient'

import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

import { defaultErrorMessage, providerErrorAction, notSupportedUploadMessage, providerSuccessAction } from "../../../../shared/constants/validationMessageHelper.js"

const providerModule = {
    namespaced: true,
    state: {
        providers: [],
        // use for storing provider in header, general info item, edudex import feed when logging in as provider
        provider: {
            id: null,
            name: null,
            websiteUrl: null,
            termsAndConditionsUrl: null,
            importUrl: null,
            canViewAssortmentInPorfolio: false
        },
        originalProvider: {},
        // use for storing the provider selected by admin via dropdown in course list, order list, edudex import list & dashboards
        selectedProvider: {
            id: null,
            name: null,
            websiteUrl: null,
            termsAndConditionsUrl: null,
            importUrl: null,
            canViewAssortmentInPorfolio: false
        }
    },
    getters: {
        getProviderName: (state) => {
            return state.provider && state.provider.name ? state.provider.name : ''
        },
        getProviderId: (state) => {
            return state.provider && state.provider.id ? state.provider.id : ''
        },
        getProviderSelectOptions: (state) => {
            const providerListFromServer = state.providers.map((item) => {
                return {
                    displayText: item.name,
                    value: item.id
                }
            })
            const newProviderList = [
                {
                    displayText: 'Alle leveranciers',
                    value: ''
                }
            ]

            return [...newProviderList, ...providerListFromServer]
        }
    },
    mutations: {
        resetProvidersState(state) {
            state.providers = []
        },
        resetProviderState(state) {
            state.provider = {}
        },
        resetSelectedProviderState(state) {
            state.selectedProvider = {
                id: null,
                name: null,
                importUrl: null,
                canViewAssortmentInPorfolio: false
            }
        },
        resetProviderImportUrl(state) {
            state.provider.importUrl = null
        },
        setProviders(state, providers) {
            state.providers = providers
        },
        setProvider(state, provider) {
            state.provider = provider
        },
        setOriginalProvider(state, provider) {
            // copy the initial state of provider details, only patch provider if value has changed
            state.originalProvider = JSON.parse(JSON.stringify(provider))
        },
        setSelectedProvider(state, selectedProvider) {
            state.selectedProvider = selectedProvider
        },
        setProviderImportUrl(state, importUrl) {
            state.provider.importUrl = importUrl
        },
        setProviderIsVatExempt(state, isVatExempt) {
            state.provider.isVatExempt = isVatExempt
        },
        setWebsiteUrl(state, websiteUrl) {
            state.provider.websiteUrl = websiteUrl
        },
        setTermsAndConditionsUrl(state, termsAndConditionsUrl) {
            state.provider.termsAndConditionsUrl = termsAndConditionsUrl
        },
        setProviderLogo(state, file) {
            if (file && file.length > 0) {
                state.provider.logo = file[0].base64FileString
            } else {
                state.provider.logo = null
            }
        },
        setProviderIntroduction(state, introductionText) {
            if (!introductionText.length) {
                state.provider.introduction = null
            } else {
                state.provider.introduction = introductionText
            }
        },
        setOleTypeBaseUrl(state, { indextr, baseUrl }) {
            state.provider.providerOleTypes[indextr].baseUrl = baseUrl
        },
        setOleTypeHasSso(state, { indextr, hasSso }) {
            state.provider.providerOleTypes[indextr].hasSso = hasSso
        }
    },
    actions: {
        fetchProviders({ commit }) {
            providerClientService
                .getProviders(0, 99999, true) // get all providers from server
                .then((response) => {
                    if (!response && !response.items) return 
                    commit('setProviders', response.items)
                })
                .catch((error) => {
                    console.error(
                        'Something went wrong while retrieving the providers items',
                        error
                    )
                })
        },
        async fetchProvider({ commit }, id) {
            if(id === null) {
                return
            }

            localStorage.setItem('providerId', id)

            try {
                const expandProperties = ['providerOleTypes, providerCostTypes']
                
                const response = await getItemById(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.providers,
                    id,
                    expandProperties,
                    false
                ) 

                if (!response) return  
              
                commit('setProvider', response)
                commit('setOriginalProvider', response)
                commit('setSelectedProvider', response)
            }
            catch (error) {
                console.error('Something went wrong while retrieving the provider details', error)
            }
        },
        patchProvider({ dispatch, commit }, { providerId, objectToBePatched }) {
            return new Promise((resolve, reject) => {
                patchItem(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.providers,
                    providerId,
                    objectToBePatched,
                    false
                )
                .then((response) => {                    
                    if (!response) return 

                    commit('setProvider', response)
                    commit('setOriginalProvider', response)

                    const toastNotification = {
                        type: 'success',
                        message: providerSuccessAction('bewerkt')
                    }                    
                    
                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })
                    resolve()
                })
                .catch((error) => {  
                    const toastNotification = {
                        type: 'error',
                        message: providerErrorAction('bewerken')
                    }

                    if (error.response.data.error.description ==  'not_supported') {
                        toastNotification.message = notSupportedUploadMessage;
                    }
                    
                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })

                    reject(error.response.data.error)
                })
            })
        },
        patchProviderOleType({ dispatch }, { providerOleTypeId, providerOleTypes }) {
            patchItem(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.providerOleTypes,
                    providerOleTypeId,
                    providerOleTypes,
                    false
                )
                .then((response) => {
                    if (!response) return 

                    const toastNotification = {
                        type: 'success',
                        message: providerSuccessAction('bewerkt')
                    }                    
                    
                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    }) 


                })
                .catch(() => {  
                    const toastNotification = {
                        type: 'error',
                        message: defaultErrorMessage
                    }                    
                    
                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    }) 


                })
        }
    }
}

export default providerModule
